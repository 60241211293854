import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import DynamicThankyou from '../../components/ThankyouPages/DynamicThankyou';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import LayoutTYP from '../../components/Containers/LayoutTYP';
import { useLayoutQueries } from '../../utils/queries';
import { capitalize } from '../../utils/Validation/characterValidation';
import storage from '../../utils/storage';
import ConditionalWrapper from '../../components/Containers/ConditionalWrapper';
import { StoreContext } from '../../components/StoreContext';
import adbutlerZones from '../../utils/adbutlerZones';
import { skeletonZoneTest } from '../../utils/adButlerTests';
import { loadScript } from '../../utils/loadScript';
import CustomCountdownHeader from '../../components/Dynamic/CustomCountdownHeader';
import CredibleDisclosure from '../../components/ThankyouPages/CredibleDisclosure';

// const coinFlip = () => {
//   return Math.random() < 0.5;
// };

// const getVariation = tytype => {
//   switch (tytype) {
//     case 'PurchaseBadCredit':
//       return 'BadCreditPurchaseVariation1';
//     case 'PurchaseGoodCredit':
//       return 'GCPVariation1';
//     case 'RefinanceGoodCredit':
//       return 'GCRVariation1';
//     case 'RefinanceBadCredit':
//       return 'BCRVariation1';
//     case 'SolarBadCredit':
//     case 'SolarGoodCredit':
//       return 'SolarOwnerVariation1';
//     case 'LifeGoodCredit':
//     case 'LifeBadCredit':
//       return 'LifeVariation1';
//     case 'HomeGoodCredit':
//     case 'HomeBadCredit':
//       return 'HomeVariation1';
//     default:
//       return 'default';
//   }
// };

const ThankYouPage = ({ path, config }) => {
  const [adIds, setAdIds] = useState([]);
  const [tyType, setTyType] = useState('');
  const [typTest, setTypTest] = useState(false);
  const [useLayoutTYPStorage, setUseLayoutTYPStorage] = useState('');
  const [params, setParams] = useState({});
  const [isILX, setIsILX] = useState(false);

  const { bannerImages, siteImages } = useLayoutQueries();
  const { setDocumentTitle, setHeadline } = useContext(StoreContext);

  const mediaAlphaOverlay = urlParams => {
    const formData = storage('session', 'get', 'jtkNetFd');
    if (
      urlParams.loan_purpose === 'health' ||
      urlParams.loan_purpose === 'med'
    ) {
      return true;
    }
    if (config.scData.siteVertical === 'ins_auto' && formData) {
      const parsedData = JSON.parse(formData);
      if (
        parsedData?.DesireHomeownersInsurance === '1' &&
        config.homeInsurance === true
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const getLayoutTYStorage =
      storage('session', 'get', 'useLayoutTYP') || false; // temp for VWO test
    setUseLayoutTYPStorage(getLayoutTYStorage);

    const IlxDisplay = [
      'fin_autorefi',
      'hs_solar',
      'hs_roofing',
      'hs_window',
      'hs_br',
      'harp',
      'va',
      '100k',
      'dpa',
      'fha',
      'generic',
      'defaultBadCredit',
      'other',
    ];

    if (IlxDisplay.includes(config.scData.siteVertical)) {
      setIsILX(true);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const query = parse(window.location.search);
    setParams(query);
    const loanPurpose = capitalize(query.loan_purpose);

    let creditRating;
    const goodCreditArr = ['Excellent', 'Good', 'Average'];
    if (goodCreditArr.includes(capitalize(query.credit_rating))) {
      creditRating = 'GoodCredit';
    } else {
      creditRating = 'BadCredit';
    }

    setTyType(`${loanPurpose}${creditRating}`);
    const adObject = adbutlerZones('ty', false, params, config);
    const lifeVertical = ['LifeGoodCredit', 'LifeBadCredit'];
    const medVertical = ['MedGoodCredit', 'MedBadCredit'];

    if (adObject[tyType] && !isILX) {
      if (params.a === '1968' && medVertical.includes(tyType)) {
        setAdIds(adObject.A1968MedZones);
      } else if (config.maMedZones && params.loan_purpose === 'med') {
        setAdIds(adObject.medSimpleHealth);
      } else if (params.a === '52' && medVertical.includes(tyType)) {
        setAdIds(adObject.MAbackUp);
      } else if (params.a === '1968' && lifeVertical.includes(tyType)) {
        setAdIds(adObject.A1968LifeZones);
      } else if (
        config.scData.siteName === 'autogosavings.com' ||
        config.surveyName === 'autogo3.insurancespecialists.com' ||
        config.scData.siteName === 'americansaversfinderauto.com'
      ) {
        setAdIds(adObject.autoMA);
      } else if (config.surveyName === 'health2.insurancespecialists.com') {
        setAdIds(adObject.HealthMA);
      } else if (
        (config.surveyName === 'med4.insurancespecialists.com' ||
          config.surveyName === 'med2.ratemarketplace.com') &&
        params.loan_purpose === 'health'
      ) {
        setAdIds(adObject.medFourHealth);
      } else if (config.maHealthZones && params.loan_purpose === 'health') {
        setAdIds(adObject.simpleHealth);
      } else if (
        (config.surveyName === 'healthsavingsselect.com' ||
          config.surveyName === 'simplehealthselectusa.com') &&
        params.loan_purpose === 'med'
      ) {
        setAdIds(adObject.MAbackUp);
      } else if (config.surveyName === 'health.insurancespecialists.com') {
        setAdIds(adObject.healthinsurance);
      } else if (config.surveyName === 'simplehealthpro.com') {
        setAdIds(adObject.simpleHealthPro);
      } else if (config.surveyName === 'life.insurancespecialists.com') {
        setAdIds(adObject[tyType]);
      } else {
        setAdIds(adObject[tyType]);
      }
    } else {
      // fail safe
      setAdIds(adObject.PurchaseGoodCredit);
    }

    // eslint-disable-next-line
  }, [tyType]);

  useEffect(() => {
    const query = parse(window.location.search);
    try {
      // eslint-disable-next-line
      const customConfig = require(`../../../data/sites/${process.env.GATSBY_SITE_TYPE}/${process.env.GATSBY_SITE_NAME}/custom.js`);
      if (customConfig?.default) {
        customConfig.default(config, setDocumentTitle, query, setHeadline);
      }
    } catch {
      // Intentionally left blank, try block is checking if 'custom.js' file exists
    }
  }, [config, setDocumentTitle, setHeadline]);

  useEffect(() => {
    const query = parse(window.location.search);

    const siteVerticals = {
      med: 'ins_med',
      health: 'ins_health',
    };

    const siteVertical =
      siteVerticals[query?.loan_purpose] || config.scData.siteVertical;

    if (!window.jtknet && mediaAlphaOverlay(query)) {
      // loadScript('//insurance.mediaalpha.com/js/serve.js');
      loadScript('https://offers.printfingertech.net/js/jtknet.js');
    }

    const windowInterval = setInterval(() => {
      if (typeof window !== 'undefined' && window.jtknet) {
        if (mediaAlphaOverlay(query)) {
          loadScript('https://api.lincx.com/load');
          // eslint-disable-next-line no-unused-expressions
          window?.jtknet?.doMediaAlphaOverlay(true, siteVertical);
          clearInterval(windowInterval);
        }
      }
    }, 100);
    setTimeout(() => {
      clearInterval(windowInterval);
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConditionalWrapper
        id="tyPageId"
        condition={useLayoutTYPStorage}
        wrapper={wrapperChildren => (
          <LayoutTYP
            config={config}
            bannerImages={bannerImages}
            siteImages={siteImages}
            tyPageVersion={params.tyPageVersion ? params.tyPageVersion : ''}
          >
            {wrapperChildren}
          </LayoutTYP>
        )}
      >
        {!isILX && !mediaAlphaOverlay(params) && (
          <DynamicThankyou
            adButlerIds={adIds}
            adButlerAcctId="169214"
            tyType={tyType}
            typTest={typTest}
            params={params}
            config={config}
            path={path}
            isILX={isILX}
          />
        )}
        {mediaAlphaOverlay(params) && (
          <>
            <CustomCountdownHeader params={params} />
            <div id="maTarget" style={{ margin: '0 auto 40px' }} />
            <CredibleDisclosure />
          </>
        )}
      </ConditionalWrapper>
      <div id="callTech" />
    </>
  );
};

ThankYouPage.propTypes = {
  path: PropTypes.string,
  config: PropTypes.object,
};

export default withThankyouWrapper(ThankYouPage);
